import axiosInstance from "../AxiosConfig";
import { createEncodedFilter, createEncodedParameters, generateRandom } from "./SaitUtils";
import { activateLoading, deactivateLoading } from "../store/features/globalLoadingReducer";
import i18next from "i18next";
import { setGlobalError } from "../store/features/globalMessageReducer";
import { downloadFile, getBlobImage, getBlobPDF, getBlobXls, getBlobXlsx } from "./FileUtils";

export async function axiosGetMakerForGrid(findFunction, token, module, dispatch) {
	try {
		const response = await axiosInstance.get(findFunction, {
			headers: {
				"X-Authentication-Strategy": "oauth2",
				"Content-Type": "application/json",
			},
			params: { access_token: token },
		});
		let rowsResp = {
			rows: [],
			totalCount: 0,
		};
		if (response?.data) {
			if (response.data?.data) {
				const res = response.data.data;
				res.id = generateRandom();
				rowsResp.rows = [res];
				rowsResp.totalCount = response.data.totalCount;

				if (rowsResp.rows) {
					if (rowsResp.rows.length === 0 && rowsResp.totalCount > 0) {
						dispatch(
							setGlobalError({
								module: module,
								errorMessage: i18next.t("error_mapping_find"),
							})
						);
					}
				}
			} else {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_find"),
					})
				);
			}
		}
		return rowsResp;
	} catch (error) {
		console.error(error);
		return {
			rows: [],
			totalCount: 0,
		};
	}
}

export async function axiosGetMultipleMakerForGrid(
	findFunction,
	token,
	module,
	dispatch,
	jsonFilter,
	orderBy,
	pageSize,
	pageNumber
) {
	try {
		const response = await axiosInstance.get(findFunction, {
			headers: {
				"X-Authentication-Strategy": "oauth2",
				"Content-Type": "application/json",
			},
			params: {
				access_token: token,
				filters: createEncodedFilter(jsonFilter, orderBy, pageSize, pageNumber),
			},
		});
		let rowsResp = {
			rows: [],
			totalCount: 0,
		};
		if (response?.data) {
			if (response.data?.data) {
				rowsResp.rows = response.data?.data.map((row) => {
					row.id = generateRandom();
					return row;
				});
				rowsResp.totalCount = response.data.totalCount;
				if (rowsResp.rows) {
					if (rowsResp.rows.length === 0 && rowsResp.totalCount > 0) {
						dispatch(
							setGlobalError({
								module: module,
								errorMessage: i18next.t("error_mapping_find"),
							})
						);
					}
				}
			} else {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_find"),
					})
				);
			}
		}
		return rowsResp;
	} catch (error) {
		console.error(error);
		return {
			rows: [],
			totalCount: 0,
		};
	}
}

export async function axiosPostMaker(postFunction) {
	const module = localStorage.getItem("1trueIdModule");
	try {
		const token = localStorage.getItem("1TrueIdToken");
		const response = await axiosInstance.post(
			postFunction.procedureName,
			postFunction.parameters,
			{
				params: { access_token: token },
				headers: {
					"X-Authentication-Strategy": "oauth2",
					"Content-Type": "application/json",
				},
			}
		);
		if (response.status === 201) {
			postFunction.dispatch(deactivateLoading(module));
			postFunction.onSuccess?.(response.data);
		} else {
			let errorMessage = "ERROR CODE " + response.status;
			postFunction.dispatch(
				setGlobalError({
					module: module,
					errorMessage: errorMessage,
				})
			);
			postFunction.dispatch(deactivateLoading(module));
			postFunction.onError?.(response.status);
		}
	} catch (error) {
		let errorMessage = "Exception Post";
		if (error?.response?.data?.message) {
			if (typeof error.response.data.message === "string") {
				errorMessage = error.response.data.message;
			}
		}
		postFunction.dispatch(
			setGlobalError({
				module: module,
				errorMessage: errorMessage,
			})
		);
		postFunction.dispatch(deactivateLoading(module));
		postFunction.onError?.(-909090);
	}
}

export async function axiosGetForGrid(
	findFunction,
	jsonFilter,
	orderBy,
	pageSize,
	pageNumber,
	token,
	module,
	dispatch
) {
	try {
		const response = await axiosInstance.get(findFunction, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				filters: createEncodedFilter(jsonFilter, orderBy, pageSize, pageNumber),
				module: module,
			},
		});
		let rowsResp = {
			rows: [],
			totalCount: 0,
		};
		if (response?.data) {
			if (response.data.items) {
				rowsResp.rows = response.data.items?.map((row) => {
					row.id = generateRandom();
					return row;
				});
				rowsResp.totalCount = response.data.totalCount;

				if (rowsResp.rows) {
					if (rowsResp.rows.length === 0 && rowsResp.totalCount > 0) {
						dispatch(
							setGlobalError({
								module: module,
								errorMessage: i18next.t("error_mapping_find"),
							})
						);
					}
				}
			} else {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_find"),
					})
				);
			}
		}

		return rowsResp;
	} catch (error) {
		console.error(error);
		return {
			rows: [],
			totalCount: 0,
		};
	}
}

export async function axiosGet(findFunction, jsonFilter, orderBy) {
	try {
		const token = localStorage.getItem("1TrueIdToken");
		const module = localStorage.getItem("1trueIdModule");
		const response = await axiosInstance.get(findFunction, {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				filters: createEncodedFilter(
					jsonFilter || [],
					orderBy || [{ property: "1", direction: "ASC" }],
					1000,
					0
				),
				module: module,
			},
		});
		let rowsResp = {
			rows: [],
			totalCount: 0,
		};
		if (response?.data) {
			rowsResp.rows = response.data.items?.map((row) => {
				row.id = generateRandom();
				return row;
			});
			rowsResp.totalCount = response.data.totalCount;
		}
		return rowsResp;
	} catch (error) {
		console.error(error);
		return {
			rows: [],
			totalCount: 0,
		};
	}
}

export async function axiosPost(procedureName, parameters) {
	var resp = {
		success: false,
		message: "",
	};

	try {
		const token = localStorage.getItem("1TrueIdToken");
		const module = localStorage.getItem("1trueIdModule");

		const response = await axiosInstance.post(
			procedureName,
			{ parameters: createEncodedParameters(parameters), module: module },
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Authentication-Strategy": "oauth2",
				},
			}
		);

		if (response.status === 200) {
			if (response.data.retval === 0) {
				resp = { ...resp, ...response.data };
				resp.success = true;
				resp.message =
					"Retval: " +
					response.data.retval +
					" --- Messaggio: " +
					response.data.messaggio;
			} else {
				resp.success = false;
				let errorMessage = response.data.messaggio;
				const dbError = /(^[A-Z0-9_]+): (-[0-9]+) - /g.exec(errorMessage); //Esempio: IWSMHLDCAL_PACK: -1401 -
				if (dbError) {
					errorMessage = errorMessage.replace(dbError[0], "");
					resp.title =
						i18next.t("error_code") +
						": " +
						response.data.retval +
						" (" +
						dbError[1] + //Nome Package
						")";
				}
				resp.message = errorMessage;
			}
		} else {
			resp.success = false;
			resp.message = "ERROR CODE " + response.status;
		}

		return resp;
	} catch (error) {
		resp.message = "Exception Post";
		if (error?.response?.data) {
			if (typeof error.response.data === "string") {
				resp.message = error.response.data;
			} else {
				if (typeof error.response.data.error === "string") {
					resp.message = error.response.data.error;
				}
			}
		} else if (error?.message) {
			resp.message = error.message;
		}
		return resp;
	}
}

export async function standardAxiosPost(params) {
	const module = localStorage.getItem("1trueIdModule");

	params.dispatch(activateLoading(module));
	try {
		const token = localStorage.getItem("1TrueIdToken");

		const response = await axiosInstance.post(
			params.procedureName,
			{ parameters: createEncodedParameters(params.parameters), module: module },
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Authentication-Strategy": "oauth2",
				},
			}
		);

		if (response.status === 200) {
			if (response.data.retval === 0) {
				params.dispatch(deactivateLoading(module));
				params.onSuccess?.(response.data);
			} else {
				let errorMessage = response.data.messaggio;
				let title;
				const dbError = /(^[A-Z0-9_]+): (-[0-9]+) - /g.exec(errorMessage); //Esempio: IWSMHLDCAL_PACK: -1401 -
				if (dbError) {
					errorMessage = errorMessage.replace(dbError[0], "");
					title =
						i18next.t("error_code") +
						": " +
						response.data.retval +
						" (" +
						dbError[1] + //Nome Package
						")";
				}
				params.dispatch(
					setGlobalError({
						module: module,
						title: title,
						errorMessage: errorMessage,
					})
				);
				params.dispatch(deactivateLoading(module));
				params.onError?.(response.data.retval);
			}
		} else {
			let errorMessage = "ERROR CODE " + response.status;
			params.dispatch(
				setGlobalError({
					module: module,
					errorMessage: errorMessage,
				})
			);
			params.dispatch(deactivateLoading(module));
			params.onError?.(response.status);
		}
	} catch (error) {
		let errorMessage = "Exception Post";
		console.log(error);
		if (error?.response?.data?.message) {
			if (typeof error.response.data.message === "string") {
				errorMessage = error.response.data.message;
			}
		}
		params.dispatch(
			setGlobalError({
				module: module,
				errorMessage: errorMessage,
			})
		);
		params.dispatch(deactivateLoading(module));
		params.onError?.(-909090);
	}
}

export async function standardAxiosGet(params) {
	const module = localStorage.getItem("1trueIdModule");

	params.dispatch(activateLoading(module));
	try {
		const token = localStorage.getItem("1TrueIdToken");

		const response = await axiosInstance.get(params.findFunction, {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				filters: createEncodedFilter(
					params.jsonFilter || [],
					params.orderBy || [{ property: "1", direction: "ASC" }],
					1000,
					0
				),
				module: module,
			},
		});

		let rowsResp = {
			rows: [],
			totalCount: 0,
		};
		if (response?.data) {
			rowsResp.rows = response.data.items?.map((row) => {
				row.id = generateRandom();
				return row;
			});
			rowsResp.totalCount = response.data.totalCount;
		}

		params.dispatch(deactivateLoading(module));
		return rowsResp;
	} catch (error) {
		console.error(error);
		params.dispatch(deactivateLoading(module));
		return {
			rows: [],
			totalCount: 0,
		};
	}
}

export function axiosFetchAllCombo(comboQueries) {
	comboQueries.forEach((comboQuery) => {
		axiosGetForCombo(
			comboQuery.findFunction,
			comboQuery.findFilters,
			comboQuery.orderBy,
			comboQuery.setItems
		);
	});
}

export function axiosGetForCombo(findFunction, jsonFilter, orderBy, setItems) {
	const token = localStorage.getItem("1TrueIdToken");
	const module = localStorage.getItem("1trueIdModule");
	return axiosInstance
		.get(findFunction, {
			headers: {
				"X-Authentication-Strategy": "oauth2",
				"Content-Type": "application/json",
			},
			params: {
				access_token: token,
				filters: createEncodedFilter(
					jsonFilter || [],
					orderBy || [{ property: "1", direction: "ASC" }],
					1000,
					0
				),
				module: module,
			},
		})
		.then((result) => {
			if (result?.data) {
				let items = result.data.items?.map((row) => {
					row.id = generateRandom();
					return row;
				});
				setItems(items);
			} else {
				setItems([]);
			}
		})
		.catch((error) => {
			console.error(error);
			setItems([]);
		});
}

export async function previewReport(dispatch, nomeReport, paramsList) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	try {
		const response = await axiosInstance.get("./downloadReportJavascript", {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				reportName: nomeReport,
				parametersList: encodeURIComponent(paramsList),
				module: module,
			},
		});

		if (response["status"] === 200) {
			var responsePdf = response["data"];
			if (!responsePdf) {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_impossible_open_file"),
					})
				);
			} else {
				var file = getBlobPDF(responsePdf);
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			}
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("failed_connection"),
				})
			);
		}
	} catch {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("failed_connection"),
			})
		);
	}
	dispatch(deactivateLoading(module));
}

export async function downloadReport(dispatch, nomeReport, paramsList, format) {
	if (!format) {
		format = ".pdf";
	}

	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	try {
		const response = await axiosInstance.get("./downloadReportJavascript", {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				reportName: nomeReport,
				parametersList: encodeURIComponent(paramsList),
				module: module,
			},
		});

		if (response["status"] === 200) {
			var responseReport = response["data"];
			if (!responseReport) {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_impossible_open_file"),
					})
				);
			} else {
				var file;
				if (format === ".pdf") {
					file = getBlobPDF(responseReport);
				} else if (format === ".xlsx") {
					file = getBlobXlsx(responseReport);
				} else if (format === ".xls") {
					file = getBlobXls(responseReport);
				}
				downloadFile(file, nomeReport);
			}
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("failed_connection"),
				})
			);
		}
	} catch {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("failed_connection"),
			})
		);
	}
	dispatch(deactivateLoading(module));
}

export async function openDocument(dispatch, path) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	try {
		const response = await axiosInstance.get("./fileServerWS/read", {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				path: encodeURIComponent(path),
			},
		});

		if (response["status"] === 200) {
			var responseFile = response["data"];
			if (!responseFile) {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_impossible_open_file"),
					})
				);
			} else {
				let fileExtension = path.split(".").pop().toLowerCase();
				if (fileExtension === "pdf") {
					var file = getBlobPDF(responseFile);
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				} else {
					let contentType = "";

					if (fileExtension === "jpg" || fileExtension === "jpeg") {
						contentType = "image/jpeg";
					} else if (fileExtension === "png") {
						contentType = "image/png";
					} else {
						contentType = "image/jpeg";
					}

					const blob = getBlobImage(contentType, responseFile);
					const blobUrl = URL.createObjectURL(blob);

					window.open(blobUrl, "_blank");
				}
			}
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("failed_connection"),
				})
			);
		}
	} catch {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("failed_connection"),
			})
		);
	}

	dispatch(deactivateLoading(module));
}

export async function downloadDocument(dispatch, path, fileName) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	try {
		const response = await axiosInstance.get("./fileServerWS/read", {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
			params: {
				path: encodeURIComponent(path),
			},
		});

		if (response["status"] === 200) {
			var responseFile = response["data"];
			if (!responseFile) {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_impossible_open_file"),
					})
				);
			} else {
				var blob;

				let fileExtension = path.split(".").pop().toLowerCase();
				if (fileExtension === "pdf") {
					blob = getBlobPDF(responseFile);
				} else if (fileExtension === "xlsx" || fileExtension === "xls") {
					blob = getBlobXlsx(responseFile);
				} else {
					let contentType = "";

					if (fileExtension === "jpg" || fileExtension === "jpeg") {
						contentType = "image/jpeg";
					} else if (fileExtension === "png") {
						contentType = "image/png";
					} else {
						contentType = "image/jpeg";
					}

					blob = getBlobImage(contentType, responseFile);
				}

				downloadFile(blob, fileName);
			}
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("failed_connection"),
				})
			);
		}
	} catch {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("failed_connection"),
			})
		);
	}

	dispatch(deactivateLoading(module));
}

export async function uploadDocument(dispatch, folderPath, documentName, uploadedFile) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	try {
		let fileToUpload = uploadedFile;
		if (documentName) {
			let extension = uploadedFile.name.substring(uploadedFile.name.lastIndexOf("."));
			fileToUpload = new File([uploadedFile], documentName + extension, {
				type: uploadedFile.type,
			});
		}

		const formData = new FormData();
		formData.append("path", folderPath);
		formData.append("data", fileToUpload);

		const response = await axiosInstance.post("fileServerWS/save", formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
		});

		var result = false;
		if (response["status"] === 200) {
			var ret = response["data"];
			if (ret.retval < 0) {
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t(ret.messaggio),
					})
				);
			} else {
				result = true;
			}
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("failed_connection"),
				})
			);
		}
	} catch (ex) {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage:
					ex.code === "ERR_NETWORK" ? i18next.t("failed_connection") : ex.response?.data,
			})
		);
	}

	dispatch(deactivateLoading(module));

	return result;
}

export async function uploadDocuments(dispatch, folderPath, uploadedFiles) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));

	const token = localStorage.getItem("1TrueIdToken");
	var result = true;
	var errorString = [];

	await Promise.all(
		[...uploadedFiles].map(async (uploadedFile) => {
			try {
				let fileToUpload = uploadedFile;

				const formData = new FormData();
				formData.append("path", folderPath);
				formData.append("data", fileToUpload);

				const response = await axiosInstance.post("fileServerWS/save", formData, {
					headers: {
						Authorization: `Bearer ${token}`,
						"X-Authentication-Strategy": "oauth2",
					},
				});

				if (response["status"] === 200) {
					var ret = response["data"];
					if (ret.retval < 0) {
						errorString.push(uploadedFile.name + ": " + i18next.t(ret.messaggio));
						result = false;
					}
				} else {
					errorString.push(uploadedFile.name + ": " + i18next.t("failed_connection"));
					result = false;
				}
			} catch (ex) {
				errorString.push(
					uploadedFile.name +
						": " +
						(ex.code === "ERR_NETWORK"
							? i18next.t("failed_connection")
							: ex.response?.data)
				);
				result = false;
			}
		})
	);

	if (!result) {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: errorString.join("\n"),
			})
		);
	}

	dispatch(deactivateLoading(module));

	return result;
}

export async function createNewFolderAxios(dispatch, folderPath, folder) {
	const module = localStorage.getItem("1trueIdModule");
	dispatch(activateLoading(module));
	const token = localStorage.getItem("1TrueIdToken");
	var result = true;
	var errorString = [];
	try {
		const formData = new FormData();
		formData.append("path", folderPath);
		formData.append("folderName", folder.name);
		await axiosInstance.post("fileServerWS/newdir", formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Authentication-Strategy": "oauth2",
			},
		});
	} catch (ex) {
		errorString.push(
			ex.code === "ERR_NETWORK"
				? i18next.t("failed_connection")
				: ex.response?.data.messaggio || ex.response?.data
		);
		result = false;
	}
	if (!result) {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: errorString.join("\n"),
			})
		);
	}
	dispatch(deactivateLoading(module));
	return result;
}
