import React, { useEffect } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { BUTTON, COMBO, GRID, TAB, TEXTFIELD } from "../../../utils/ComponentList";
import { axiosFetchAllCombo, axiosPostMaker } from "../../../utils/AxiosUtils";
import { checkOpen } from "../../../utils/SaitUtils";
import { setGlobalError, setGlobalMessage } from "../../../store/features/globalMessageReducer";
import SaitDialog from "../../../components/atomic/SaitDialog";
import EditPanel from "../../../components/template/EditPanel";
import SaitButton from "../../../components/atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { activateLoading, deactivateLoading } from "../../../store/features/globalLoadingReducer";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function NuovoMaker(props) {
	/*************
	 *	DICHIARAZIONI   *
	 **************/
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);

	/*************
	 *	MODEL   *
	 **************/
	const object = {
		username: "",
		password: "",
		confirmPassword: "",
		name: "",
		qrcodePrefix: "",
		idMaker: "",
		norma: "",
		brand: "",
	};

	/*************
	 *	  STATI    *
	 **************/
	const [attemptSave, setAttemptSave] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const [findMaker, setFindMaker] = React.useState(object);
	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [itemsIwstdnorma, setItemsIwstdnorma] = React.useState([]);

	/*************
	 *	LISTENER   *
	 **************/

	useEffect(() => {
		const comboQueriesFiltered = [
			{
				findFunction: "/norma/findIwstdnorma",
				orderBy: [{ property: "descNormativa", direction: "ASC" }],
				findFilters: [{ idMaker: findMaker.idMaker }],
				setItems: setItemsIwstdnorma,
			},
		];
		axiosFetchAllCombo(comboQueriesFiltered);
		// eslint-disable-next-line
	}, [findMaker.idMaker && index === 2]);

	/*************
	 *	  GRID    *
	 **************/
	const fields = [
		{
			title: i18next.t("new"),
			type: TAB,
			items: [
				{
					title: i18next.t("maker"),
					items: [
						{
							label: i18next.t("username"),
							name: "username",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("password"),
							name: "password",
							password: "password",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("confirm_password"),
							name: "confirmPassword",
							password: "password",
							type: TEXTFIELD,
						},
						{
							label: i18next.t("business_name"),
							name: "name",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("barcode_prefix"),
							name: "qrcodePrefix",
							type: TEXTFIELD,
						},
					],
				},
			],
		},
		{
			title: i18next.t("detail"),
			type: TAB,
			items: [
				{
					name: "idMaker",
					type: TEXTFIELD,
					label: i18next.t("type_a_maker_id"),
					value: findMaker.idMaker,
					sx2: {
						marginBottom: 2.7,
						marginLeft: "30%",
						marginTop: 3.5,
						maxWidth: 300,
					},
				},
				{
					type: BUTTON,
					label: i18next.t("filter_grid"),
					disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
					sx: { margin: 1, marginTop: 3.6, marginRight: "30%", color: "#FFFFFF" },
					onClick: () => {
						setReloadGrid(true);
					},
					endIcon: <RefreshIcon />,
				},
				{
					type: GRID,
					inLineButtonFilter: true,
					controlledFilter: reloadGrid,
					setControlledFilter: setReloadGrid,
					hideFooter: true,
					height: 60,
					gridParams: {
						findFunction: findMaker && "/maker/" + findMaker?.idMaker,
						fetchData: "axiosGetMakerForGrid",
						orderBy: [{ property: "idTemplate", direction: "ASC" }],
						columns: [
							{
								headerName: i18next.t("maker"),
								field: "uid",
								width: 100,
							},
							{
								headerName: i18next.t("username"),
								field: "username",
								flex: 1,
							},
							{
								headerName: i18next.t("business_name"),
								field: "name",
								flex: 1,
							},
							{
								headerName: i18next.t("barcode_prefix"),
								field: "qrcodePrefix",
								width: 150,
							},
						],
					},
					filters: [],
				},
			],
		},
		{
			title: i18next.t("regulation"),
			type: TAB,
			items: [
				{
					title: i18next.t("assignment"),
					items: [
						{
							label: i18next.t("maker"),
							name: "idMaker",
							type: TEXTFIELD,
							required: true,
							defaultValue: findMaker.idMaker,
						},
						{
							label: i18next.t("regulation"),
							name: [{ storeName: "idTipoNorma", comboName: "idTipoNorma" }],
							type: COMBO,
							description: "$(idTipoNorma) - $(titolo)",
							required: true,
							items: itemsIwstdnorma,
							disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
							afterChange: (selection) => {
								setFindMaker((prevState) => {
									return {
										...prevState,
										norma: selection.idTipoNorma,
									};
								});
							},
						},
					],
				},
			],
		},
		{
			title: i18next.t("brand"),
			type: TAB,
			height: 850,
			items: [
				{
					title: i18next.t("brand"),
					items: [
						{
							label: i18next.t("maker"),
							name: "idMaker",
							type: TEXTFIELD,
							required: true,
							defaultValue: findMaker.idMaker,
						},
						{
							label: i18next.t("brand"),
							name: "brand",
							type: TEXTFIELD,
							disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
							required: true,
						},
					],
				},
			],
		},
	];

	/*************
	 *	  FILTERS    *
	 **************/

	/*************
	 *	  BUTTONS    *
	 **************/

	/*************
	 *	FUNCTIONS   *
	 **************/

	const handleOnSave = () => {
		if (findMaker.password === findMaker.confirmPassword) {
			setAttemptSave(true);
			dispatch(activateLoading(module));
			const requiredFields = fields[0].items[0].items.filter((e) => {
				if (e.required) {
					return e.name;
				}
				return "";
			});
			let allOk = requiredFields.every((e) => {
				//rimuovo spazi vuoti iniziali e finali
				findMaker[e.name] = findMaker[e.name].replace(/^\s+|\s+$/g, "");
				return findMaker.hasOwnProperty(e.name) && findMaker[e.name] !== "";
			});
			if (!allOk) {
				dispatch(deactivateLoading(module));
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_enter_all_required_fields"),
					})
				);
			} else {
				//controllo che lo username sia una mail
				const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				allOk = regex.test(findMaker["username"]);
				if (allOk) {
					onSave();
				} else {
					dispatch(deactivateLoading(module));
					dispatch(
						setGlobalError({
							module: module,
							errorMessage: i18next.t("enter_an_email_in_the_username_field!"),
						})
					);
				}
			}
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("wrong_password"),
				})
			);
		}
	};

	const onSave = async () => {
		const req = {
			username: findMaker.username,
			password: findMaker.password,
			name: findMaker.name,
			qrcodePrefix: findMaker.qrcodePrefix.toUpperCase(),
		};
		await axiosPostMaker({
			dispatch: dispatch,
			procedureName: "/maker/",
			parameters: req,
			onSuccess: (response) => {
				setFindMaker((prevState) => {
					return {
						...prevState,
						idMaker: response.data.uid,
					};
				});
				dispatch(deactivateLoading(module));
				setAttemptSave(false);
				props.reload();
				dispatch(
					setGlobalMessage({
						module: module,
						title: i18next.t("creation_completed"),
						message: i18next.t("maker_created_successfully"),
					})
				);
			},
		});
	};

	const onSaveNorma = async () => {
		dispatch(activateLoading(module));
		if (findMaker.norma && findMaker.idMaker.replace(/^\s+|\s+$/g, "")) {
			const req = {
				idMaker: findMaker.idMaker,
				norma: findMaker.norma,
			};
			await axiosPostMaker({
				dispatch: dispatch,
				procedureName: "/norma/insertNorma",
				parameters: req,
				onSuccess: () => {
					dispatch(deactivateLoading(module));
					dispatch(
						setGlobalMessage({
							module: module,
							title: i18next.t("association_completed"),
							message: i18next.t("association_completed_successfully"),
						})
					);
				},
			});
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	const onSaveBrand = async () => {
		dispatch(activateLoading(module));
		const requiredFields = fields[3].items[0].items.filter((e) => {
			if (e.required) {
				return e.name;
			}
			return "";
		});
		let allOk = requiredFields.every((e) => {
			//rimuovo spazi vuoti iniziali e finali
			findMaker[e.name] = findMaker[e.name].replace(/^\s+|\s+$/g, "");
			return findMaker.hasOwnProperty(e.name) && findMaker[e.name] !== "";
		});
		if (allOk) {
			const req = {
				idMaker: findMaker.idMaker,
				brand: findMaker.brand,
			};
			await axiosPostMaker({
				dispatch: dispatch,
				procedureName: "/norma/insertBrand",
				parameters: req,
				onSuccess: () => {
					dispatch(deactivateLoading(module));
					props.close();
					setFindMaker(object);
					setIndex(0);
				},
			});
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	/*************
	 *	HTML   *
	 **************/

	return (
		<>
			<SaitDialog
				open={checkOpen(props.open)}
				title={i18next.t("new") + " " + i18next.t("maker")}
				width={"lg"}
				scroll={"paper"}
				flexDirection="column"
				content={
					<>
						<EditPanel
							attemptSave={attemptSave}
							stateElement={findMaker}
							setFunction={(e) => setFindMaker(e)}
							fields={fields}
							setTabIndex={(e) => setIndex(e)}
							open={props.open}
						/>
					</>
				}
				actions={
					<>
						<SaitButton
							text={i18next.t("close")}
							color="error"
							endIcon={<CancelIcon />}
							onClick={() => {
								props.close();
								setFindMaker(object);
								setAttemptSave(false);
								setIndex(0);
							}}
						/>
						<div style={{ flexGrow: 1 }} />
						{index !== 1 && (
							<SaitButton
								text={i18next.t("save")}
								endIcon={<SaveIcon />}
								onClick={
									index === 2
										? onSaveNorma
										: index === 3
										? onSaveBrand
										: handleOnSave
								}
								color={"success"}
							/>
						)}
					</>
				}
			/>
		</>
	);
}
