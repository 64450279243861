import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserPreferences, unsetUserPreferences } from "./store/features/authReducer";
import axiosInstance from "./AxiosConfig";
import IndexDrawer from "./scenes/Index/IndexDrawer";
import Login from "./scenes/Login/Login";
import Starter from "./scenes/Starter/Starter";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material";
import { browserLocalization, changeLocalization } from "./localization/i18n";

function App() {
	const token = useSelector((state) => state["auth"]["token"]);

	const dispatch = useDispatch();
	const [theme, colorMode] = useMode();
	const [autoLogin, setAutoLogin] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [readQrPage, setReadQrPage] = React.useState(false);
	const [idProfile, setIdProfile] = React.useState("");

	useEffect(() => {
		(async () => {
			const currentPath = window.location.pathname;
			if (currentPath === "/normativa") {
				const params = new URLSearchParams(window.location.search);
				const idProfileParam = params.get("uid");

				setReadQrPage(true);
				setIdProfile(idProfileParam);
			} else {
				const localToken = localStorage.getItem("1TrueIdToken");
				if (localToken) {
					setAutoLogin(true);
					try {
						const response = await axiosInstance.get("oauth/user/is-authenticated", {
							params: {
								access_token: localToken,
							},
							headers: {
								"X-Authentication-Strategy": "oauth2",
							},
						});
						if (response.status === 200) {
							const username = localStorage.getItem("1TrueIdUsername");
							const roles = JSON.parse(localStorage.getItem("roles"));
							// controllo che l'utenza connessa sia associata a un maker
							let isMaker = false;
							roles.forEach((role) => {
								if (role === "maker") {
									isMaker = true;
									return;
								}
							});
							response.data = {};
							response.data.username = username;
							response.data.access_token = localToken;
							response.data.scope = roles;
							response.data.isMaker = isMaker;
							dispatch(setUserPreferences(response.data));
							changeLocalization(response.data.userPreferences?.linguaIso);
						} else {
							dispatch(unsetUserPreferences());
							browserLocalization();
						}
					} catch (error) {
						dispatch(unsetUserPreferences());
						browserLocalization();
					}
					setAutoLogin(false);
				} else {
					dispatch(unsetUserPreferences());
					browserLocalization();
				}

				//Caricamento minimo 2 secondi
				setTimeout(function () {
					setLoading(false);
				}, 2000);
			}

			// }, 1);
		})();

		return () => {};
	}, [dispatch]);

	useEffect(() => {
		setTimeout(function () {
			setLoading(false);
		}, 2000);
	}, [dispatch]);

	/** AXIOS INTERCEPTORS **/
	axiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error?.response?.status === 401) {
				dispatch(unsetUserPreferences());
			}
			return Promise.reject(error);
		}
	);

	return (
		<div className="app">
			<ColorModeContext.Provider
				// @ts-ignore
				value={colorMode}
			>
				<ThemeProvider
					// @ts-ignore
					theme={theme}
				>
					{loading || autoLogin ? (
						<Starter />
					) : token ? (
						<IndexDrawer theme={theme} colorMode={colorMode} />
					) : (
						<Login />
					)}
				</ThemeProvider>
			</ColorModeContext.Provider>
		</div>
	);
}

export default App;
