import React from "react";
import "./Starter.css";

export default function Starter(props) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				width: "100%",
				backgroundImage: `url(${process.env.PUBLIC_URL + window.Configs.backgroundImg})`,
				backgroundColor: window.Configs.homeAppbarMain,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className="Starter">
				<header className="Starter-header">
					<img
						src={process.env.PUBLIC_URL + "/logo-starter.png"}
						className="Starter-logo"
						alt="logo"
					/>
				</header>
			</div>
		</div>
	);
}
