import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
// import FontDownloadIcon from "@mui/icons-material/FontDownload";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function SaitTextField(props) {
	const { noAutocomplete, password, ...textProps } = props;
	const defaultSize = "small";
	const [handleClickShowPassword, setHandleClickShowPassword] = React.useState(true);
	// const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);

	// const checkCapsLock = (event) => {
	// 	if (event.getModifierState("CapsLock")) {
	// 		setIsCapsLockOn(true);
	// 	} else {
	// 		setIsCapsLockOn(false);
	// 	}
	// };

	return (
		<TextField
			{...textProps}
			type={
				textProps.type
					? textProps.type
					: password && handleClickShowPassword
					? "password"
					: "text"
			}
			size={textProps.size || defaultSize}
			onKeyDown={
				(event) => (textProps.onKeyDown ? textProps.onKeyDown(event) : password)
				// ? checkCapsLock(event)
				// : null
			}
			inputProps={{
				maxLength: textProps.maxLength,
				autoComplete: noAutocomplete ? "new-password" : null,
			}}
			InputProps={{
				endAdornment: textProps.endadornment,
				startAdornment: textProps.startadornment,
				// password && isCapsLockOn
				// ? {
				// 		endAdornment: (
				// 			<InputAdornment position="end">
				// 				<FontDownloadIcon />
				// 			</InputAdornment>
				// 		),
				//   }
				// :
				...(password
					? {
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => {
											setHandleClickShowPassword(!handleClickShowPassword);
										}}
										edge="end"
									>
										{handleClickShowPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
					  }
					: {}), // Un oggetto vuoto se la condizione non è soddisfatta
			}}
			variant={textProps.variant || (textProps.disabled ? "filled" : "outlined")}
			sx={{
				...textProps.sx,
				"& .Mui-disabled": {
					color: "#303030",
				},
				"& .MuiInputBase-input.Mui-disabled": {
					WebkitTextFillColor: "#151515",
				},
				...textProps.sx2,
			}}
		/>
	);
}
