// @ts-nocheck
import React, { Fragment, useRef } from "react";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RenderIcon } from "../../ComponentController";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import List from "@mui/material/List";
import SaitTextField from "../../components/atomic/SaitTextField";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { setMenuOpen } from "../../store/features/generalConfigReducer";
import { useDispatch } from "react-redux";
import { setSearchTextClicked } from "../../store/features/generalConfigReducer";
import { t } from "i18next";

const StyledTextField = styled(SaitTextField)({
	"& label": {
		color: window.Configs.menuItemTextMain,
	},
	"& label.Mui-focused": {
		color: window.Configs.menuItemTextMain,
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: window.Configs.menuItemTextMain,
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: window.Configs.menuItemTextMain,
		},
		"&:hover fieldset": {
			borderColor: window.Configs.menuItemTextMain,
		},
		"&.Mui-focused fieldset": {
			borderColor: window.Configs.menuItemTextMain,
		},
		"&.MuiInputBase-root": {
			color: window.Configs.menuItemTextMain,
		},
	},
});

const openedMixin = (theme, drawerwidth) => ({
	width: drawerwidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px + ${window.Configs.paddingBigIcon || 0}px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
	zIndex: 9998,
	width: drawerwidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme, drawerwidth),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

export default function SaitDrawer(props) {
	const menu = [{ id: "M001", text: t("maker") }];

	const dispatch = useDispatch();

	const [searchValue, setSearchValue] = React.useState("");

	const searchTextFieldRef = useRef("");

	const handleDrawerClose = () => {
		dispatch(setMenuOpen(false));
	};

	const onChangeSearch = (newValue) => {
		setSearchValue(newValue);
	};

	const setAutofocus = (value) => {
		dispatch(setSearchTextClicked(value));
	};

	let debounceSearchTimer;
	const handleChangeSearch = (newValue) => {
		if (searchTextFieldRef.current) {
			searchTextFieldRef.current["value"] = newValue;
		}

		// Cancella il timer se l'utente continua a scrivere entro il debounceTime
		clearTimeout(debounceSearchTimer);

		// Imposta un nuovo timer per chiamare l'aggiornamento dello stato dopo il debounceTime
		debounceSearchTimer = setTimeout(() => {
			onChangeSearch(newValue);
		}, 300);
	};

	return (
		<Drawer variant="permanent" open={props.open} drawerwidth={props.drawerwidth}>
			<Box
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "logoBox.main",
				}}
			>
				<DrawerHeader>
					<Box
						m={2}
						sx={{
							flexGrow: 1,
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<img src={process.env.PUBLIC_URL + "/logo.png"} height={32} alt="logo" />
					</Box>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon color="menuBoxIcon" />
					</IconButton>
				</DrawerHeader>
				<Divider />
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					flexGrow: 1,
					backgroundColor: "menuBox.main",
					color: "menuItemText.main",
				}}
			>
				<List>
					{menu.map(
						(menuElement, index) =>
							(!searchValue ||
								menuElement.text
									.toUpperCase()
									.includes(searchValue.toUpperCase())) && (
								<Fragment key={menuElement.id}>
									<ListItemButton
										sx={{ pl: 3 }}
										onClick={() =>
											props.handleOpenTab({
												id: menuElement.id,
												label: menuElement.text,
											})
										}
									>
										<ListItemIcon
											sx={{
												minWidth: 30,
												height: 30,
												alignItems: "center",
											}}
										>
											<RenderIcon
												component={{
													id: menuElement.id,
													text: props.open ? null : menuElement.text,
												}}
											/>
										</ListItemIcon>
										{props.open && <ListItemText primary={menuElement.text} />}
									</ListItemButton>
								</Fragment>
							)
					)}
				</List>
			</Box>
			{props.open && (
				<Box
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "menuBox.main",
						display: props.open ? "block" : "none",
					}}
				>
					<Divider />
					<StyledTextField
						noAutocomplete
						label={t("search")}
						variant="outlined"
						onChange={(e) => handleChangeSearch(e.target.value)}
						inputRef={searchTextFieldRef}
						onFocus={() => {
							setAutofocus(true);
						}}
						onBlur={() => {
							setAutofocus(false);
						}}
						startadornment={
							<InputAdornment position="start">
								<SearchIcon color="menuBoxIcon" />
							</InputAdornment>
						}
						endadornment={
							<InputAdornment position="end">
								<IconButton onClick={() => handleChangeSearch("")}>
									<ClearIcon color="menuBoxIcon" />
								</IconButton>
							</InputAdornment>
						}
						sx={{ m: 1, width: props.drawerWidth - 15 }}
					/>
				</Box>
			)}
		</Drawer>
	);
}
