import React from "react";
import { CONTAINS } from "../../utils/FiltersOperators";
import { TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import AddIcon from "@mui/icons-material/Add";
import NuovoMaker from "./NuovoMaker/NuovoMaker";

export default function Maker() {
	/*************
	 *	DICHIARAZIONI   *
	 **************/

	/*************
	 *	MODEL   *
	 **************/

	/*************
	 *	  STATI    *
	 **************/

	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [openNewMaker, setOpenNewMaker] = React.useState(false);

	/*************
	 *	LISTENER   *
	 **************/

	/*************
	 *	  GRID    *
	 **************/
	const gridParams = {
		findFunction: "/maker/",
		fetchData: "axiosGetMultipleMakerForGrid",
		orderBy: [
			{
				property: "uid",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("maker"),
				field: "uid",
				width: 100,
			},
			{
				headerName: i18next.t("username"),
				field: "username",
				width: 250,
			},
			{
				headerName: i18next.t("business_name"),
				field: "name",
				width: 250,
			},
			{
				headerName: i18next.t("barcode_prefix"),
				field: "qrcodePrefix",
				width: 150,
			},
			{
				headerName: i18next.t("address"),
				field: "indirizzo",
				width: 250,
			},
			{
				headerName: i18next.t("address_no"),
				field: "numeroCivico",
				flex: 1,
			},
			{
				headerName: i18next.t("city"),
				field: "citta",
				flex: 1,
			},
			{
				headerName: i18next.t("province"),
				field: "provincia",
				flex: 1,
			},
			{
				headerName: i18next.t("country"),
				field: "nazione",
				flex: 1,
			},
			{
				headerName: i18next.t("postal_code"),
				field: "codicePostale",
				flex: 1,
			},
			{
				headerName: i18next.t("state"),
				field: "statoAccount",
				flex: 1,
			},
		],
	};

	/*************
	 *	  FILTERS    *
	 **************/
	const filters = [
		{
			label: i18next.t("username"),
			name: "username",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("city"),
			name: "citta",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("province"),
			name: "provincia",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("state"),
			name: "statoAccount",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
	];

	/*************
	 *	  BUTTONS    *
	 **************/

	const topButtons = [
		{
			text: i18next.t("new") + " " + i18next.t("maker"),
			icon: <AddIcon color="primary" />,
			variant: "text",
			function: () => {
				setOpenNewMaker(true);
			},
		},
	];

	/*************
	 *	FUNCTIONS   *
	 **************/

	/*************
	 *	HTML   *
	 **************/

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				topButtons={topButtons}
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
				resetFilter
				autoload
			/>
			<NuovoMaker
				open={openNewMaker}
				close={() => {
					setOpenNewMaker(false);
				}}
				reload={() => setReloadGrid(true)}
			/>
		</>
	);
}
