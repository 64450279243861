import dayjs from "dayjs";

export function createEncodedFilter(jsonFilterArray, orderBy, pageSize, pageNumber) {
	const jsonObject = {};
	jsonObject.items = jsonFilterArray;
	jsonObject.orderBy = orderBy;
	jsonObject.pageSize = pageSize;
	jsonObject.pageNumber = pageNumber;
	let filterParameterString = JSON.stringify(jsonObject);
	let filterParameterEncoded = encodeURIComponent(filterParameterString);
	return filterParameterEncoded;
}

export function createEncodedParameters(parameters) {
	let parametersString = JSON.stringify(parameters);
	let parametersEncoded = encodeURIComponent(parametersString);
	return parametersEncoded;
}

export function generateRandom() {
	const randomId = Math.random().toString(36).substring(2, 9);
	return randomId;
}

export function formatDate(params) {
	if (params.value) {
		return dayjs(params.value).format("DD/MM/YYYY");
	} else {
		return null;
	}
}

export function checkOpen(open) {
	if (open) {
		if (typeof open === "object") {
			if (Object.keys(open).length !== 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	} else {
		return false;
	}
}

export function getTodayDate() {
	//ritorna la data di oggi a mezzanotte ora locale
	const today = new Date();
	return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

export function formatDateTime(params) {
	if (params.value) {
		return dayjs(params.value).format("DD/MM/YYYY HH:mm");
	} else {
		return null;
	}
}

export function removeObjectWithId(arr, id) {
	const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

	if (objWithIdIndex > -1) {
		arr.splice(objWithIdIndex, 1);
	}

	return arr;
}

export function formatLabel(string, values) {
	let newString;
	// Sostituisco le variabili
	newString = string.replace(/\$\(([A-z]+)\)/g, function (match, index) {
		return typeof values[index] == "undefined"
			? match
			: values[index] || values[index] === 0
			? values[index]
			: "";
	});
	// Sostituisco le variabili Date
	newString = newString.replace(/\$d\(([A-z]+)\)/g, function (match, index) {
		return typeof values[index] == "undefined"
			? match
			: values[index] || values[index] === 0
			? dayjs(values[index]).format("DD/MM/YYYY")
			: "";
	});
	// Sostituisco le variabili Date con Ora
	newString = newString.replace(/\$t\(([A-z]+)\)/g, function (match, index) {
		return typeof values[index] == "undefined"
			? match
			: values[index] || values[index] === 0
			? dayjs(values[index]).format("DD/MM/YYYY HH:mm")
			: "";
	});
	return newString;
}

export function formatCurrency(value) {
	const formatter = new Intl.NumberFormat("it-IT", {
		style: "currency",
		currency: "EUR",

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(value);
}

export function cellBackgroundColor(backgroundColor) {
	return {
		backgroundColor: backgroundColor,
		width: "100%", // Imposta la larghezza della cella al 100%
		height: "100%", // Imposta l'altezza della cella al 100%
		display: "flex", // Usa il layout flessibile per occupare tutto lo spazio disponibile
		alignItems: "center", // Allinea verticalmente il contenuto al centro
		paddingLeft: "3px",
	};
}
