import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModule } from "./store/features/moduleReducer";
import { Tooltip, Typography } from "@mui/material";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Maker from "./scenes/Maker/Maker";

const componentList = (component, theme) => {
	switch (component.id) {
		case "M001":
			return {
				component: <Maker />,
				icon: <ContactPageIcon />,
			};
		default:
			return "";
	}
};

export function RenderComponent(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		const dispatchModule = (moduleId) => {
			dispatch(setModule(moduleId));
		};

		dispatchModule(props.activeTabId);
	}, [props.activeTabId, dispatch]);

	// @ts-ignore
	return <Fragment>{componentList(props.component, props.theme).component}</Fragment>;
}

export function RenderIcon(props) {
	const title = props.component.text ? (
		<Typography fontSize={14}>{props.component.text}</Typography>
	) : (
		<Typography fontSize={14}>{props.component.id}</Typography>
	);
	const placement = props.component.text ? "right-start" : "bottom";
	return (
		<Fragment>
			<Tooltip PopperProps={{ style: { zIndex: 10001 } }} title={title} placement={placement}>
				{
					// @ts-ignore
					componentList(props.component, props.theme).icon
				}
			</Tooltip>
		</Fragment>
	);
}
